/**
 * Menu
**/

function menu() {

    const menu = document.querySelector('.c-menu__nav');
    const links = document.querySelectorAll('.c-menu__anchor');
    const toggle = document.querySelector('.c-menu__toggle');
    const modalBg = document.querySelector('.c-modal-bg');

    function checkBreakpoint() {
        // If screensize is bigger than mobile, change a11y params
    };





    // Listeners
    // ========================================

    // Toggle
    toggle.addEventListener('click', () => {
        toggleMenu();
    });

    // Links
    links.forEach(link => {
        link.addEventListener('click', () => {
            closeMenu();
        });
    })

    // Modal
    modalBg.addEventListener('click', () => {
        toggleMenu();
    });





    // Functions
    // ========================================

    function toggleMenu() {
        if (menu.classList.contains('--is-active')) {
            closeMenu();
        } else {
            openMenu();
        }
    }

    function openMenu() {
        toggle.classList.add('--is-active');
        menu.classList.add('--is-active');
        menu.setAttribute('aria-expanded', 'true');

        modalBg.style.display = 'block';
        setTimeout(() => {
            modalBg.classList.add('--is-visible');
        }, 50);
    }

    function closeMenu() {
        toggle.classList.remove('--is-active');
        menu.classList.remove('--is-active');
        menu.removeAttribute('aria-expanded');

        modalBg.classList.remove('--is-visible');
        setTimeout(() => {
            modalBg.style.display = 'none';
        }, 400);
    }
};

export { menu };

// window.addEventListener("scroll", function() {
//     var navbar = document.querySelector(".c-menu");
//     var navbarLinks = navbar.querySelectorAll(".c-menu__list-item a");
  
//     navbarLinks.forEach(function(link) {
//       var href = link.getAttribute("href");
  
//       // Verificar si el enlace es una URL completa
//       if (isUrl(href)) {
//         return; // Salir de la iteración actual
//       }
  
//       var section = document.querySelector(href);
  
//       if (section) {
//         var sectionTop = section.offsetTop;
//         var sectionHeight = section.offsetHeight;
//         var scrollPosition = window.pageYOffset;
  
//         if (
//           scrollPosition >= sectionTop &&
//           scrollPosition < sectionTop + sectionHeight
//         ) {
//           link.classList.add("c-menu__anchor--hover");
//           console.log(link);
//         } else {
//           link.classList.remove("c-menu__anchor--hover");
//         }
//       }
//     });
//   });
  
//   // Función para verificar si un enlace es una URL completa
//   function isUrl(str) {
//     try {
//       new URL(str);
//       return true;
//     } catch (error) {
//       return false;
//     }
//   }
  
  
  
  